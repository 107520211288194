import React from 'react';
import PjmDesignLogo from '../PjmDesignLogo';
import * as Icon from 'react-bootstrap-icons';

function Footer() {
	return (
		<footer>
			<a href="/" className="text-center flex-shrink-1">
				<PjmDesignLogo color="inverted" mini circle id="footerLogo" />
			</a>
			<div className="d-flex flex-column align-items-start">
				<h3 className='h5 mb-1'>Connect:</h3>
				<div className="d-flex gap-4 flex-wrap justify-content-center align-items-start">
					<a className="flex-shrink-1 App-link" href="mailto:contact@pjm.design?subject=Website%20Inquiry" target={'_blank'} rel="noopener noreferrer">
						<Icon.Envelope icon="envelope" /> Contact Me
					</a>
					<a className="flex-shrink-1 App-link" href="https://www.linkedin.com/in/pj-mullen/" target={'_blank'} rel="noopener noreferrer">
						<span style={{ color: 'var(--linkedin-blue' }}>
							<Icon.Linkedin icon="envelope" />
						</span>
						LinkedIn
					</a>
					<a className="flex-shrink-1 App-link" href="https://twitch.tv/tectix0" target={'_blank'} rel="noopener noreferrer">
						<span style={{ color: 'var(--twitch-purple)' }}>
							<Icon.Twitch icon="envelope" />
						</span>
						Twitch
					</a>
					<a className="flex-shrink-1 App-link" href="https://youtu.be/@tectix0" target={'_blank'} rel="noopener noreferrer">
						<span style={{ color: 'var(--youtube-red)' }}>
							<Icon.Youtube icon="envelope" />
						</span>
						YouTube
					</a>
					<a className="flex-shrink-1 App-link" href="https://reddit.com/u/tectix0" target={'_blank'} rel="noopener noreferrer">
						<span style={{ color: 'var(--reddit-orange)' }}>
							<Icon.Reddit icon="envelope" />
						</span>
						Reddit
					</a>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
