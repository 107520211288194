import React from 'react';
import UnderConstructionScreen from './components/UnderConstructionScreen';

import './App.scss';
import Footer from './components/Footer';

const DEV = process.env.NODE_ENV === 'development';

// import * as BootstrapComponents from 'react-bootstrap';
// console.log( BootstrapComponents );

!DEV && console.log(
	`
  %c@@@@@@@@@@@@@@@@@@@@@  %c000000000000000
   %c@@@             @@@              %c000
    %c@@@           @@@   %c888888888  %c000
     %c@@@         @@@   %c888888888  %c000
      %c@@@       @@@     %c888      %c000
       %c@@@     @@@       %c888    %c000
        %c@@@   @@@   %c888888888  %c000
         %c@@@ @@@   %c888888888  %c000
          %c@@@@@  %c0  %c888      %c000
           %c@@@  %c000  %c888    %c000
            %c@@@  %c000  %c888  %c000
             %c@@@  %c000  %c8  %c000
              %c@@@  %c000   000
               %c@@@  %c000 000
                %c@@@  %c00000
                 %c@@@  %c000
                  %c@@@  %c0
                   %c@@@
                    @
    `,
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
);

function App() {
	return (
		<div id="App">
			<header className="App-header"></header>
			<main>
				<UnderConstructionScreen />
			</main>
			<Footer />
		</div>
	);
}

export default App;
