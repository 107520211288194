import React from 'react';

import VisuallyHidden from '../VisuallyHidden';

function PjmDesignLogo({ color = 'full', mini, circle, raster, ...props }) {
	const [svgCode, setSvgCode] = React.useState('');
    const [loadingState, setLoadingState] = React.useState('loading');

    const colorCode = {
		full: 'C',
		white: 'W',
		black: 'B',
        inverted: 'I',
	};

    const logoName = `pjmdesign-${mini ? 'mini-' : ''}${circle ? 'CIR-' : ''}${colorCode[color]}`;
    const svgFileName = `${logoName}.svg`;

    React.useEffect(() => {
        setLoadingState('loading');

        fetch(require(`../../img/${svgFileName}`))
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const reader = new FileReader();
                reader.onload = function () {
                    setSvgCode(reader.result);
                    setLoadingState('loaded');
                };
                reader.readAsText(blob);
            })
            .catch(error => {
                const errorMessage = `There has been a problem with fetching the svg file: ${error}`;
                console.error(errorMessage, error);
                setLoadingState(<span>{errorMessage}</span>);
            });
    }, [svgFileName]);

	if (raster) {
		const rasterFile = require(`../../img/${logoName}.png`);

		const rasterElement = <img src={rasterFile} alt="PJM Design Logo" {...props} />;

		return rasterElement;
	} else {
        if (loadingState === 'loading') {
            return <div className='svg-loading' {...props} ><VisuallyHidden>SVG image loading in...</VisuallyHidden></div>
        } else if (loadingState === 'error') {
            return <span>Error loading SVG</span>
        } else {
            return <div className='svg-container' dangerouslySetInnerHTML={{ __html: svgCode }} {...props} />;
        }
	}
}

export default PjmDesignLogo;
